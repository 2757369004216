import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { scroller, Events } from 'react-scroll';
import {
  AnalyticsProvider,
  CallToAction,
  Container,
  FaqForV1,
  GameBrowserForV1,
  HeadTagsHandler,
  LadderTournamentCardList,
  Layout,
  LeaderboardTournamentCardList,
  PartnersForV1,
  SignUpContainer,
  Testimonials,
} from '../components';
import styles from './index.module.css';
import { sendGAEvent } from '../helpers';
import { AuthAndApiContext } from '../contexts';

export default function Index() {
  const intl = useIntl();
  const router = useRouter();
  const { api, user, userBasicToken, deviceToken, setDeviceToken } =
    useContext(AuthAndApiContext);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  async function getDeviceToken() {
    const response = await api.get('/users/get_firebase_access_token/');
    const responseJson = await response.json();

    setDeviceToken(responseJson.token);
  }

  if (user !== null && document.referrer === 'https://wallet.gamerarena.com/') {
    getDeviceToken().then(() => {
      const basicToken = userBasicToken?.slice(6);

      router.push(
        `https://wallet.gamerarena.com/?token=${basicToken}&deviceToken=${deviceToken}`,
      );
    });
  }

  // useEffect(() => {
  //   const ali = window.localStorage.getItem('popupModal');

  //   if (ali !== '1') {
  //     setIsModalOpen(true);
  //     window.localStorage.setItem('popupModal', '1');
  //   }
  // }, []);

  useEffect(() => {
    const { action } = router.query;

    if (action) {
      let offset = 0;

      if (action === 'FAQ') {
        offset = -80;
      }

      if (action === 'Hero') {
        offset = -80;
      }

      if (action === 'GameInfo') {
        offset = -80;
      }

      if (action === 'Tournaments') {
        offset = -220;
      }

      if (action === 'Testimonials') {
        offset = -80;
      }

      if (action === 'sign-up' || action === 'sign-in') {
        offset = -90;
      }

      const toElement =
        action === 'sign-up' || action === 'sign-in'
          ? 'signUpOrSignIn'
          : router.query?.action;

      scroller.scrollTo(toElement as string, {
        duration: 800,
        delay: 0,
        smooth: 'easeInQuart',
        offset,
      });

      if (action === 'sign-up' || action === 'sign-in') {
        Events.scrollEvent.register('end', () => {
          const el = document.getElementById('sign-in-or-sign-up');
          el?.classList.add(styles.highlight);
          setTimeout(() => {
            el?.classList.remove(styles.highlight);
          }, 2000);
        });
      }
    }
  }, [router.query]);

  useEffect(() => {
    sendGAEvent({
      category: 'Register',
      event: 'view',
      label: 'v1',
    });

    sendGAEvent({
      category: 'Landing Page GA',
      event: 'View Landing Page',
      label: 'v1',
    });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  // const toggleModal = () => {
  //   setIsModalOpen(!isModalOpen);
  // };
  // const handleClick = () => {
  //   window.open(
  //     'https://traderjoexyz.com/avalanche/trade?outputCurrency=0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2',
  //     '_blank',
  //   );
  // };
  return (
    <AnalyticsProvider category='Landing'>
      <HeadTagsHandler
        description={`${intl.formatMessage({
          defaultMessage:
            'Gamer Arena is a competitive gaming platform where you can earn GA Coins by playing popular games like Clash Royale, Pubg, Fifa.',
        })}`}
        title={`${intl.formatMessage({
          defaultMessage:
            'Competitive Gaming Platform | Play to Earn | GA Coin',
        })}`}
      />

      {/* {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 50,
          }}
        >
          <div
            style={{
              position: 'relative',
              width: 500,
              height: 750,
              background: '#2C2C2C', // Koyu gri arka plan
              padding: '20px',
              borderRadius: '10px',
              textAlign: 'center',
              color: 'white',
            }}
          >
            <button
              onClick={toggleModal}
              style={{
                position: 'absolute',
                color: '#fff',
                border: 'none',
                fontSize: '50px',
                cursor: 'pointer',
                zIndex: 10000,
                top: 20,
                right: 20,
                background: 'none',
              }}
              type='button'
            >
              &times;
            </button>
            <Image
              alt='GA Logo'
              height={450}
              src={logo}
              style={{ marginBottom: '20px', opacity: 0.8 }}
              width={450}
            />

            <h2>
              <FormattedMessage defaultMessage='READY TO JOIN THE ARENA?' />
            </h2>
            <br />
            <h5>
              <FormattedMessage defaultMessage='REGISTER NOW!' />
            </h5>
            <br />
            <h5>
              <FormattedMessage defaultMessage='US based Challengers can buy GAU at Trader Joe' />
            </h5>
            <br />
            <br />
            <button
              onClick={handleClick}
              style={{
                backgroundColor: '#EC2D44',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
              type='button'
            >
              <FormattedMessage defaultMessage='Register' />
            </button>
          </div>
        </div>
      )} */}

      <Layout className={styles.index} displayFooter id='Hero'>
        <SignUpContainer role='main' />
        {/*     <ScrollIndicator icon='mouse' /> */}
        <GameBrowserForV1 id='GameInfo' />
        <PartnersForV1 />

        <Container className={styles.container} id='Tournaments'>
          <LeaderboardTournamentCardList
            hideIfEmpty
            includeStatuses={['FUTURE', 'IN_PROGRESS']}
            title={intl.formatMessage({
              defaultMessage: 'Ongoing Leaderboard Tournaments',
            })}
            viewAllLinkHref='/tournaments?section=leaderboard'
          />

          <LadderTournamentCardList
            hideIfEmpty
            includeStatuses={['FUTURE', 'IN_PROGRESS']}
            title={intl.formatMessage({
              defaultMessage: 'Ongoing Ladder Tournaments',
            })}
            viewAllLinkHref='/tournaments?section=discord'
          />
        </Container>

        <Testimonials id='Testimonials' />
        <FaqForV1 id='FAQ' />
        <CallToAction
          content={intl.formatMessage({
            defaultMessage:
              'Join thousands of players competing in Gamer Arena and start winning right now.',
          })}
          title={intl.formatMessage({
            defaultMessage: 'Welcome to the next level in gaming!',
          })}
        />
      </Layout>
    </AnalyticsProvider>
  );
}
